<template>
  <v-card>
    <v-dialog v-model="filterMenu" persistent width="550">
      <v-card>
        <v-card-title>
          Filters
          <v-btn class="ml-auto" icon color="error" @click="filterMenu = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-container class="pa-5">
          <v-row>
            <!-- <v-col sm="12">
              <v-select
                :items="[
                  { value: null, text: 'UTC+00:00'},
                  { value: 'America/New_York', text: 'Eastern Standard Time'},
                  { value: 'America/Chicago', text: 'Central Standard Time'},
                  { value: 'America/Denver', text: 'Mountain Standard Time'},
                  { value: 'America/Los_Angeles', text: 'Pacific Standard Time'},
                ]"
                label="Select Timezone"
                v-model="selectedTimezone"
                hide-details
                outlined
                dense
              >
              </v-select>
            </v-col> -->
            <v-col sm="12">
              <v-row class="align-center">
                <span class="mx-3">Date Range</span>
                <date-range-picker
                  v-model="dateRange"
                  :appendToBody="true"
                  :showDropdowns="true"
                  :max-date="getMaxDate"
                  :ranges="ranges"
                  @toggle="toggleDateRange"
                >
                </date-range-picker>
                <v-btn color="warning" class="ml-2" @click="dateRange = { startDate: null, endDate: null }">
                  Clear
                </v-btn>
              </v-row>
            </v-col>
            <v-col sm="12">
              <v-select
                v-model="selectedClasses"
                :items="service_classes"
                label="Filter Service Class"
                :menu-props="{
                  value: serviceClassMenu,
                  maxHeight: 400,
                }"
                hide-details
                clearable
                multiple
                outlined
                dense
                @click:clear="selectedClasses = []"
                @click="serviceClassMenu = true"
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="toggleServiceClass">
                    <v-list-item-action>
                      <v-icon :color="selectedClasses.length > 0 ? 'indigo darken-4' : ''">
                        {{ icon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Select All </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template v-slot:selection="{ item }">
                  <v-chip>
                    <span>{{ getCode(item) }}</span>
                  </v-chip>
                </template>
                <template v-slot:append-item>
                  <v-divider class="mb-2"></v-divider>
                  <v-btn small text color="success" class="ml-2" @click="serviceClassMenu = false">Confirm</v-btn>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="update">Apply</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-tabs v-model="tab" centered>
      <div class="filters">
        <v-btn color="primary" @click="filterMenu = true">
          <v-icon class="mr-2">mdi-filter</v-icon>
          Filter
        </v-btn>
      </div>
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#account-report"> Report </v-tab>
      <v-tab href="#account-labels"> Labels </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item value="account-report">
        <v-container fluid>
          <v-row>
            <v-col sm="12">
              <vue-apex-charts
                class="custom-apex-chart"
                v-if="showChart"
                :options="chartOptions"
                :series="series"
                height="400"
              />
            </v-col>
            <v-col sm="12">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Domain</th>
                      <th>Service Class</th>
                      <th>Spend</th>
                      <th>Pieces</th>
                      <th>Insurance</th>
                      <th>Revenue</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(s, i) in services" :key="i">
                      <td>{{ s.group }}</td>
                      <td>{{ s.name }}</td>
                      <td>{{ s.spend }}</td>
                      <td>{{ s.pieces }}</td>
                      <td>{{ s.insurance }}</td>
                      <td>{{ s.revenue }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item value="account-labels">
        <data-table
          title="Label"
          item-key="name"
          :headers="headers"
          :items="items"
          :options.sync="options"
          :loading.sync="loading"
          :items-length="totalItems"
          :page="page"
          :pageCount="pageCount"
          :items-per-page="itemsPerPage"
          :timezone="getTz()"
          :hide-add-btn="true"
          @update="fetchData"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<style lang="scss" scoped>
.filters {
  > button {
    position: absolute;
    left: 7px;
    top: 5px;
  }
}
</style>

<script>
import { mapGetters } from 'vuex'

import FilterMixin from '@/mixins/filter-mixin'
import DataTable from '@/components/data-table/DataTable.vue'

import moment from 'moment-timezone'
import axios from 'axios'
import _ from 'lodash'

import DateRangePicker from 'vue2-daterange-picker'
import VueApexCharts from 'vue-apexcharts'

export default {
  mixins: [FilterMixin],
  components: {
    DataTable,
    DateRangePicker,
    VueApexCharts,
  },
  data: () => ({
    tab: null,
    options: {},
    totalItems: 0,
    page: 1,
    pageCount: 0,
    itemsPerPage: 250,
    headers: [
      { text: 'Actions', value: 'actions', align: 'center', sortable: false, width: 50 },
      // {
      //   text: 'ID',
      //   align: 'start',
      //   sortable: false,
      //   value: 'id',
      // },
      { text: 'Tracking', value: 'tracking' },
      { text: 'User', value: 'user', width: 140 },
      { text: 'Order Number', value: 'order_number', width: 140 },
      { text: 'Marketplace', value: 'marketplace' },
      { text: 'Insurance', value: 'insurance_price', align: 'end', width: 110 },
      { text: 'Rate', value: 'rate', align: 'end' },
      { text: 'Signature', value: 'signature', align: 'end', width: 110 },
      { text: 'Surcharge', value: 'surcharge', align: 'end', width: 110 },
      { text: 'Total Cost', value: 'total_cost', align: 'end', width: 110 },
      { text: 'Service Class', value: 'code', width: 130 },
      // { text: 'Return', value: 'return', align: 'center', sortable: false, },
      { text: 'Shipment Date', value: 'created_at', width: 180 },
    ],
    items: [],
    searchTerm: '',
    showChart: false,
    services: [],
    chartOptions: [],
    series: [],
    desiredOrder: [
      {code: 'GA_L', label: 'Ground Advantage Lightweight', name: ['GA_LIGHTWEIGHT'], group: 'Domestic'},
      {code: 'GA_H', label: 'Ground Advantage Heavyweight', name: ['GA_HEAVYWEIGHT'], },
      {code: 'GA_O', label: 'Ground Advantage Overweight', name: ['GA_OVERWEIGHT'], },
      {code: 'PM', label: 'Priority Mail', name: ['PM_A', 'PM_B'], },
      {code: 'PM_O', label: 'Priority Mail Overweight', name: ['PM_OVERWEIGHT'], },
      {code: 'EM', label: 'Priority Mail Express', name: ['EM_A', 'EM_B'], },
      {code: 'EM_O', label: 'Priority Mail Overweight', name: ['EM_OVERWEIGHT'], },
      {code: 'MEDIA', label: 'Media Mail', name: ['MEDIA'], },

      {code: 'FCPIS', label: 'First-Class Package International Service', name: ['FCPIS'], group: 'International' },
      {code: 'PMI', label: 'Priority Mail International', name: ['PMI_A', 'PMI_B'], },
      {code: 'PMI_O', label: 'Priority Mail International Overweight', name: ['PMI_OVERWEIGHT'], },
      {code: 'EM', label: 'Priority Mail Express International', name: ['EMI_A', 'PMI_B'], },
      {code: 'EM_O', label: 'Priority Mail Express International Overweight', name: ['EMI_OVERWEIGHT'], },
      
      {code: 'GA_L_PRINTED', label: 'Ground Advantage Lightweight - Printed', name: ['RETURN_GA_LIGHTWEIGHT_PRINTED'], group: 'Scan-Based Return'},
      {code: 'GA_L_CHARGED', label: 'Ground Advantage Lightweight - Charged', name: ['RETURN_GA_LIGHTWEIGHT_CHARGED'], },
      {code: 'GA_H_PRINTED', label: 'Ground Advantage Heavyweight - Printed', name: ['RETURN_GA_HEAVYWEIGHT_PRINTED'], },
      {code: 'GA_H_CHARGED', label: 'Ground Advantage Heavyweight - Charged', name: ['RETURN_GA_HEAVYWEIGHT_CHARGED'], },
      {code: 'GA_O_PRINTED', label: 'Ground Advantage Overweight - Printed', name: ['RETURN_GA_OVERWEIGHT_PRINTED'], },
      {code: 'GA_O_CHARGED', label: 'Ground Advantage Overweight - Charged', name: ['RETURN_GA_OVERWEIGHT_CHARGED'], },
      {code: 'PM_PRINTED', label: 'Priority Mail - Printed', name: ['RETURN_PM_PRINTED'], },
      {code: 'PM_CHARGED', label: 'Priority Mail - Charged', name: ['RETURN_PM_CHARGED'], },
      {code: 'PM_O_PRINTED', label: 'Priority Mail Overweight - Printed', name: ['RETURN_PM_OVERWEIGHT_PRINTED'], },
      {code: 'PM_O_CHARGED', label: 'Priority Mail Overweight - Charged', name: ['RETURN_PM_OVERWEIGHT_CHARGED'], },
      {code: 'EM_PRINTED', label: 'Priority Mail Express - Printed', name: ['RETURN_EM_PRINTED'], },
      {code: 'EM_CHARGED', label: 'Priority Mail Express - Charged', name: ['RETURN_EM_CHARGED'], },
      {code: 'PM_O_PRINTED', label: 'Priority Mail Express Overweight - Printed', name: ['RETURN_EM_OVERWEIGHT_PRINTED'], },
      {code: 'PM_O_CHARGED', label: 'Priority Mail Express Overweight - Charged', name: ['RETURN_EM_OVERWEIGHT_CHARGED'], },

      {label: 'TOTAL CHARGED: ', name: [
        'GA_LIGHTWEIGHT', 'GA_HEAVYWEIGHT', 
        'PM_A', 'PM_B', 
        'EM_A', 'EM_B', 
        'MEDIA', 
        'FCPIS',
        'PMI_A', 'PMI_B',
        'EMI_A', 'PMI_B', 
        'RETURN_GA_LIGHTWEIGHT_CHARGED',
        'RETURN_GA_HEAVYWEIGHT_CHARGED',
        'RETURN_PM_CHARGED',
        'RETURN_EM_CHARGED',
      ]},
      {label: 'TOTAL PRINTED: ', name: [
        'RETURN_GA_LIGHTWEIGHT_PRINTED',
        'RETURN_GA_HEAVYWEIGHT_PRINTED',
        'RETURN_PM_PRINTED',
        'RETURN_EM_PRINTED',
      ], },
      {label: 'TOTAL OVERWEIGHT CHARGED: ', name: [
        'GA_OVERWEIGHT',
        'PM_OVERWEIGHT',
        'PMI_OVERWEIGHT', 
        'EMI_OVERWEIGHT',
        'RETURN_GA_OVERWEIGHT_CHARGED',
        'RETURN_PM_OVERWEIGHT_CHARGED',
        'RETURN_EM_OVERWEIGHT_CHARGED',
      ], },
      {label: 'TOTAL OVERWEIGHT PRINTED: ', name: [
        'RETURN_GA_OVERWEIGHT_PRINTED',
        'RETURN_PM_OVERWEIGHT_PRINTED',
        'RETURN_EM_OVERWEIGHT_PRINTED',
      ], },
    ],
  }),

  computed: {
    ...mapGetters(['getTz']),
  },

  async mounted() {
    document.title = 'Labels';
    
    await this.fetchData()
  },

  methods: {
    getCode(item) {
      if (item.value === 'sbr') return 'SBR'
      let _service_classes = JSON.parse(localStorage.getItem('service_classes'))
      const service = _service_classes.find(s => s.id === item.value)
      return service.code
    },

    async fetchData(search = '') {
      try {
        this.loading = true
        if (this.$root.progressbar) {
          this.$root.progressbar.show()
        }
        this.searchTerm = search

        const token = this.$store.state.token
        const url = process.env.VUE_APP_API_URL

        let params = {
          sbr: true,
          start_date: moment.tz(this.dateRange.startDate, this.getTz()).startOf('day').toISOString(),
          end_date: moment.tz(this.dateRange.endDate, this.getTz()).endOf('day').toISOString(),
          service_classes: this.selectedClasses,
        }

        if (!this.selectedClasses.includes('sbr')) {
          delete params.sbr
        }

        const { data: summary } = await axios.get(`${url}/api/order/summary`, {
          headers: { Authorization: `Bearer ${token}` },
          params: params,
        })

        let graph = summary.map(s => {
          return {
            name: s.name ?? s.code,
            code: s.code,
            spend: s.total_spent ?? 0,
            pieces: s.total_labels ?? 0,
            insurance: s.insurance_spent ?? 0,
            revenue: s.total_revenue,
          }
        })

        // graph.push({
        //   name: 'Returns',
        //   code: 'Returns',
        //   spend: 0,
        //   pieces: this.user.returns,
        //   revenue: 0,
        // })

        this.series = [
          { name: 'Spend', data: graph.map(g => g.spend) },
          { name: 'Pieces', data: graph.map(g => g.pieces) },
          { name: 'Insurance', data: graph.map(g => g.insurance) },
          { name: 'Revenue', data: graph.map(g => g.revenue) },
        ]
        this.chartOptions = {
          chart: { type: 'bar', height: 350 },
          colors: ['#0bb4ff', '#50e991', '#e6d800', '#9b19f5', '#ffa300', '#dc0ab4', '#b3d4ff', '#00bfa0'],
          // colors: [ "#7eb0d5", "#b2e061", "#bd7ebe", "#ffb55a", "#ffee65", "#beb9db", "#fdcce5", "#8bd3c7" ],
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded',
            },
          },
          dataLabels: { enabled: false },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
          },
          xaxis: { categories: graph.map(g => g.code) },
          fill: { opacity: 1 },
        }

        // Services
        // this.services = graph.map( s => {
        //   return {
        //     name: s.name,
        //     code: s.code,
        //     spend: s.spend ? `$${s.spend.toLocaleString('en-US')}` : '$0',
        //     pieces: s.pieces ?? 0,
        //     insurance: s.insurance ? `$${s.insurance.toLocaleString('en-US')}` : '$0',
        //     revenue: s.revenue ? `$${s.revenue.toLocaleString('en-US')}` : '$0',
        //   }
        // })

        this.services = this.desiredOrder.map(desiredService => {
          let service = graph.filter(s => desiredService.name.includes(s.name))

          service = service.reduce((obj, item)=>{
            obj.pieces+=item.pieces
            obj.spend+=item.spend
            obj.insurance+=item.insurance
            obj.revenue+=item.revenue
            return obj
          }, {...service[0], pieces: 0, spend: 0, insurance: 0, revenue: 0})

          console.log(service, 'this is the service')
          if (service) {
            return {
              group: desiredService.group,
              name: desiredService.label,
              code: service.code,
              spend: service.spend ? `$${service.spend.toLocaleString('en-US')}` : '$0',
              insurance: service.insurance ? `$${service.insurance.toLocaleString('en-US')}` : '$0',
              pieces: service.pieces ?? 0,
              revenue: service.revenue ? `$${service.revenue.toLocaleString('en-US')}` : '$0',
            }
          } else {
            // Return default values for a missing service
            return {
              group: desiredService.group,
              name: desiredService.label,
              code: '',
              spend: '$0',
              insurance: '$0',
              pieces: 0,
              revenue: '',
            }
          }
        })

        this.showChart = true

        // Get Order Data
        if (_.isEmpty(this.options)) {
          return
        }

        const { itemsPerPage, page, sortBy, sortDesc } = this.options

        if (!sortBy[0]) {
          sortBy[0] = 'created_at'
          sortDesc[0] = true
        }

        params = Object.assign({}, params, {
          kw: search,
          limit: itemsPerPage,
          page: page,
          order_by: sortBy[0] ? sortBy[0] : 'created_at',
          order_direction: sortDesc[0] ? 'desc' : 'asc',
        })

        const {
          data: { data, meta },
        } = await axios.get(`${url}/api/order`, {
          headers: { Authorization: `Bearer ${token}` },
          params: params,
        })

        this.page = meta.current_page
        this.pageCount = meta.last_page
        this.totalItems = meta.total
        this.itemsPerPage = meta.per_page

        this.items = data.map(o => {
          return {
            id: o.id,
            tracking: o.tracking_id || o.return_tracking_id,
            user: o.user,
            user_id: o.user_id,
            order_number: o.order_number, // ?? o.ref_id,
            marketplace: o.marketplace,
            code: o.code,
            insurance_price: o.insurance_price ? `$${o.insurance_price.toLocaleString('en-US')}` : '$0',
            rate: o.rate ? `$${o.rate.toLocaleString('en-US')}` : '$0',
            signature: o.tracking_price ? `$${o.tracking_price.toLocaleString('en-US')}` : '$0',
            surcharge: o.surcharge ? `$${o.surcharge.toLocaleString('en-US')}` : '$0',
            total_cost: o.total_price ? `$${o.total_price.toLocaleString('en-US')}` : '$0',
            shipping_service_id: o.shipping_service_id,
            return: o.order_status_id === 5 ? 1 : 0,
            created_at: o.created_at,
          }
        })
      } catch (error) {
        console.error('error', error)
      } finally {
        this.loading = false
        if (this.$root.progressbar) {
          this.$root.progressbar.hide()
        }
      }
    },
  },
}
</script>
